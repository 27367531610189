import {
  Breadcrumb,
  Button,
  Checkbox,
  Divider,
  Input,
  Select,
  Space,
  Tag,
  TagProps,
} from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import Title from 'antd/lib/typography/Title';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  GetAppointmentSuggestion,
  GetOrder,
} from '../../../../rrm-common/dto/order';
import { GetStable } from '../../../../rrm-common/dto/stable';
import { DateSuggestionStatus } from '../../app/DateSuggestionConstants';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { APP_TABLE_PAGE_SIZE } from '../../app/localStorageConstants';
import {
  getCurrencyFromNumber,
  getDateSuggestionStatusFormat,
  getOrderTypeString,
} from '../../app/utils';
import { archiveOrders, getOrders } from '../../redux/reducers/orderReducer';
import './Orders.scss';
import { faArchive } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SearchOutlined } from '@ant-design/icons';

const { Option } = Select;

function Orders() {
  const dispatch = useAppDispatch();
  const { orders, archiveLoading } = useAppSelector((state) => state.order);
  const [loadingOrders, setLoadingOrders] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number | null>(null);
  const [pageSize, setPageSize] = useState<number | null>(null);
  const [statusValues, setStatusValues] =
    useState<{ key: string; value: string }[]>();
  const [statusFilter, setStatusFilter] = useState<string>('');
  const history = useHistory();
  const [selectedOrderRowKeys, setSelectedOrderRowKeys] = useState<number[]>(
    []
  );
  const [archivedNotShow, setArchivedNotShow] = useState<boolean>(true); //Default, filter is checked

  const triggerGetOrders = () => {
    setLoadingOrders(true);
    dispatch(getOrders({ filter: statusFilter }));
  };

  const archiveSelectedOrders = () => {
    dispatch(archiveOrders(selectedOrderRowKeys));
    setSelectedOrderRowKeys([]);
  };

  const inviteRowSelection = {
    selectedRowKeys: selectedOrderRowKeys,
    onChange: (selectedRowKeys: React.Key[], selectedRows: GetOrder[]) => {
      if (selectedRows.length <= 0) {
        setSelectedOrderRowKeys([]);
      } else {
        setSelectedOrderRowKeys(selectedRows.map((sr) => sr.id));
      }
    },
  };

  const handleSearch = (selectedKeys: any, confirmd: any, dataIndex: any) => {
    confirmd();
  };

  const handleReset = (clearFilters: any, confirmd: any, dataIndex: any) => {
    clearFilters();
    confirmd();
  };

  const getColumnSearchProps = (dataIndex: any) => ({
    filterDropdown: (temp: any) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={() => {
            //this.searchInput = node;
          }}
          type={
            dataIndex === 'budget'
              ? 'number'
              : // : dataIndex === 'createdAt'   //Makes no sense, because if i want all data from "2021", this wouldn't work! Better to use "text"
                // ? 'date'
                'text'
          }
          value={temp.selectedKeys[0]}
          onChange={(e) =>
            temp.setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(temp.selectedKeys, temp.confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() =>
              handleSearch(temp.selectedKeys, temp.confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}
          >
            Suchen
          </Button>
          <Button
            onClick={() =>
              handleReset(temp.clearFilters, temp.confirm, dataIndex)
            }
            size='small'
            style={{ width: 90 }}
          >
            Entfernen
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value: any, record: GetOrder) => {
      if (dataIndex === 'customer') {
        var firstname: string =
          record.customer.userProfile.firstname.toUpperCase();
        var lastname: string =
          record.customer.userProfile.lastname.toUpperCase();

        return (
          firstname.includes(value.toUpperCase()) ||
          lastname.includes(value.toUpperCase())
        );
      }

      if (dataIndex === 'createdAt') {
        return moment(record.createdAt)
          .format('L')
          .includes(value.toUpperCase());
      }

      if (dataIndex === 'stable') {
        if (record.stable)
          return (
            record.stable?.street.toUpperCase().includes(value.toUpperCase()) ||
            record.stable?.streetNumber
              .toUpperCase()
              .includes(value.toUpperCase()) ||
            record.stable?.postalCode
              .toUpperCase()
              .includes(value.toUpperCase()) ||
            record.stable?.city.toUpperCase().includes(value.toUpperCase())
          );
        else return '--'.includes(value.toUpperCase());
      }

      if (dataIndex === 'appointmentSuggestions') {
        if (record.appointmentSuggestion) {
          var suggestionText = getDateSuggestionStatusFormat(
            record.appointmentSuggestion?.status,
            record.appointmentSuggestion?.appointmentSuggestionItems
          ).text;
          return suggestionText.includes(value.toUpperCase());
        } else return false;
      }

      if (dataIndex === 'appVersion') {
        return (record.customer.version ?? 'N/A')
          .toUpperCase()
          .includes(value.toUpperCase());
      }

      if (dataIndex === 'type') {
        return getOrderTypeString(record.type)
          .toUpperCase()
          .includes(value.toUpperCase());
      }

      if (dataIndex === 'budget') {
        return (record.budget ? record.budget : 0)
          .toString()
          .includes(value.toUpperCase());
      }

      return true;
    },
  });

  useEffect(() => {
    const params = new URLSearchParams();
    const savedPageSize = localStorage.getItem(APP_TABLE_PAGE_SIZE);

    if (params) {
      const pageParam = history.location.search.split('=');

      if (pageParam.length >= 2) {
        setCurrentPage(Number.parseInt(pageParam[1]));
      } else {
        setCurrentPage(1);
      }
    } else {
      setCurrentPage(1);
    }

    if (savedPageSize !== null) {
      setPageSize(Number.parseInt(savedPageSize));
    } else {
      setPageSize(10);
    }

    // * Get Status values
    const vals: { key: string; value: string }[] = [];

    for (const currKey in DateSuggestionStatus) {
      if (Object.prototype.hasOwnProperty.call(DateSuggestionStatus, currKey)) {
        const element = DateSuggestionStatus[currKey];

        vals.push({
          key: element.title,
          value: element.query,
        });
      }
    }

    setStatusValues(vals);
  }, [history.location.search]);

  useEffect(() => {
    dispatch(getOrders({ filter: statusFilter }));
    const intervalId = setInterval(() => {
      setLoadingOrders(true);
      dispatch(getOrders({ filter: statusFilter }));
    }, 30000);

    return () => {
      clearInterval(intervalId);
    };
  }, [dispatch, statusFilter]);

  useEffect(() => {
    if (orders) {
      setLoadingOrders(false);
    }
  }, [orders]);

  const handlePageChange = (pageNumber: number) => {
    const params = new URLSearchParams();
    if (pageNumber) {
      params.append('page', pageNumber.toString());
      setCurrentPage(pageNumber);
    } else {
      params.delete('page');
    }
    history.push({ search: params.toString() });
  };

  const handlePageSizeChange = (pageSize: number) => {
    localStorage.setItem(APP_TABLE_PAGE_SIZE, pageSize.toString());
  };

  const handleFilterChange = (value: string) => {
    setStatusFilter(value);
  };

  const columns = (): ColumnsType<GetOrder> => {
    var columns: ColumnsType<GetOrder> = [
      {
        title: 'Datum',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (val: Date) => moment(val).format('L'),
        sorter: (a, b) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
        ...getColumnSearchProps('createdAt'),
      },
      {
        title: 'Auftragart',
        dataIndex: 'type',
        key: 'type',
        render: (val: number) => {
          let tagProps: TagProps = { color: 'orange' };
          if (val === 3) {
            tagProps = { color: 'red' };
          }

          return <Tag {...tagProps}>{getOrderTypeString(val)}</Tag>;
        },
        sorter: (a, b) =>
          getOrderTypeString(a.type) > getOrderTypeString(b.type) ? -1 : 1,
        ...getColumnSearchProps('type'),
      },
      {
        title: 'Kunde',
        key: 'customer',
        dataIndex: 'customer',
        render: (val) =>
          `${val.userProfile.firstname} ${val.userProfile.lastname}`,
        sorter: (a, b) =>
          `${a.customer.userProfile.firstname.toUpperCase()} ${a.customer.userProfile.lastname.toUpperCase()}` >
          `${b.customer.userProfile.firstname.toUpperCase()} ${b.customer.userProfile.lastname.toUpperCase()}`
            ? -1
            : 1,
        ...getColumnSearchProps('customer'),
      },
      {
        title: 'Budget',
        key: 'budget',
        dataIndex: 'budget',
        render: (val: number | null) =>
          val ? getCurrencyFromNumber(val) : '--',
        sorter: (a, b) =>
          (a.budget ? a.budget : 0) > (b.budget ? b.budget : 0) ? -1 : 1,
        ...getColumnSearchProps('budget'),
      },
      {
        title: 'Stalladresse',
        key: 'stable',
        dataIndex: 'stable',
        render: (val: GetStable) =>
          val
            ? `${val.street} ${val.streetNumber}, ${val.postalCode} ${val.city}`
            : '--',
        sorter: (a, b) =>
          (a.stable
            ? `${a.stable.street.toUpperCase()} ${a.stable.streetNumber.toUpperCase()}, ${a.stable.postalCode.toUpperCase()} ${a.stable.city.toUpperCase()}`
            : '') >
          (b.stable
            ? `${b.stable.street.toUpperCase()} ${b.stable.streetNumber.toUpperCase()}, ${b.stable.postalCode.toUpperCase()} ${b.stable.city.toUpperCase()}`
            : '')
            ? -1
            : 1,
        ...getColumnSearchProps('stable'),
      },
      {
        title: 'Termine',
        key: 'appointmentSuggestion',
        dataIndex: 'appointmentSuggestion',
        render: (val: GetAppointmentSuggestion | null) => {
          if (val === undefined || val === null) {
            return null;
          }
          var statusVal = getDateSuggestionStatusFormat(
            val.status,
            val.appointmentSuggestionItems
          );
          return <Tag color={statusVal.color}>{statusVal.text}</Tag>;
        },
        sorter: (a, b) =>
          (a.appointmentSuggestion
            ? getDateSuggestionStatusFormat(
                a.appointmentSuggestion.status,
                a.appointmentSuggestion.appointmentSuggestionItems
              )
            : '') >
          (b.appointmentSuggestion
            ? getDateSuggestionStatusFormat(
                b.appointmentSuggestion.status,
                b.appointmentSuggestion.appointmentSuggestionItems
              )
            : '')
            ? -1
            : 1,
        ...getColumnSearchProps('appointmentSuggestion'),
      },
      {
        title: 'App Version',
        key: 'appVersion',
        sorter: (a, b) =>
          (a.customer.version ? a.customer.version : '') >
          (b.customer.version ? b.customer.version : '')
            ? -1
            : 1,
        render: (text, record) => record.customer.version ?? 'N/A',
        ...getColumnSearchProps('appVersion'),
      },
    ];

    if (!archivedNotShow) {
      columns.push({
        title: 'Archiviert',
        key: 'archived',
        sorter: (a, b) => (a.archived > b.archived ? -1 : 1),
        render: (text, record) =>
          record.archived ? (
            <Tag color={'orange'}>
              <FontAwesomeIcon icon={faArchive} />
            </Tag>
          ) : undefined,
      });
    }

    columns.push({
      title: '',
      key: 'action',
      render: (text, record) => (
        <Space size='middle'>
          <Link to={`/orders/${record.id}`}>Details</Link>
        </Space>
      ),
      ...getColumnSearchProps('action'),
    });

    return columns;
  };

  const getTextForArchiveButton = () => {
    var archived = false;
    var unarchived = false;

    selectedOrderRowKeys.forEach((key) => {
      var order = orders.find((o) => o.id === key);
      if (order) {
        if (order.archived) archived = true;
        if (!order.archived) unarchived = true;
      }
    });

    if (archived && unarchived) return 'Archivieren / Zurücksetzen';
    if (archived) return 'Zurücksetzen';
    return 'Archivieren';
  };

  return (
    <>
      <div className='orders__container'>
        <Breadcrumb>
          <Breadcrumb.Item>Anfragen</Breadcrumb.Item>
        </Breadcrumb>
        <Divider />
        <Title>Auftragsübersicht</Title>
        <Space>
          <Button
            loading={loadingOrders}
            disabled={archiveLoading}
            onClick={() => triggerGetOrders()}
            type='primary'
          >
            Aktualisieren
          </Button>
          <Select
            disabled={archiveLoading}
            defaultValue=''
            style={{ width: 200 }}
            onChange={(val) => handleFilterChange(val)}
          >
            {statusValues &&
              statusValues.map((x) => (
                <Option key={x.key} value={x.value}>
                  {x.key}
                </Option>
              ))}
          </Select>
          <Button
            loading={archiveLoading}
            disabled={selectedOrderRowKeys.length === 0}
            onClick={() => archiveSelectedOrders()}
            type='default'
            danger
            icon={<FontAwesomeIcon icon={faArchive} />}
          >
            {getTextForArchiveButton()}
          </Button>
          <Checkbox
            disabled={archiveLoading}
            checked={archivedNotShow}
            onChange={() => {
              setArchivedNotShow(!archivedNotShow);
            }}
          >
            Archivierte ausblenden
          </Checkbox>
        </Space>
        {currentPage && pageSize && (
          <Table
            dataSource={
              archivedNotShow
                ? orders.filter((order) => !order.archived)
                : orders
            }
            columns={columns()}
            rowKey={(record) => record.id}
            pagination={{
              onChange: (page, size) => handlePageChange(page),
              defaultCurrent: currentPage,
              onShowSizeChange: (current, size) => handlePageSizeChange(size),
              defaultPageSize: pageSize,
            }}
            rowSelection={{
              type: 'checkbox',
              ...inviteRowSelection,
            }}
            onRow={(record) => {
              return {
                onClick: () => {
                  var idx = selectedOrderRowKeys.findIndex((si) => {
                    return si.toString() === record.id.toString();
                  });
                  var tmpArray: any = JSON.stringify(selectedOrderRowKeys);
                  tmpArray = JSON.parse(tmpArray);

                  if (idx === -1) {
                    tmpArray.push(record.id);
                  } else {
                    tmpArray.splice(idx, 1);
                  }

                  if (!archiveLoading) setSelectedOrderRowKeys(tmpArray);
                },
              };
            }}
          />
        )}
      </div>
    </>
  );
}
export default Orders;
