import React from "react";
import { Switch, useRouteMatch } from "react-router-dom";
import { PrivateRoute } from "../../index";
import Customer from "./Customers";

function CustomersIndex() {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <PrivateRoute path={`${path}/`}>
        <Customer />
      </PrivateRoute>
    </Switch>
  );
}

export default CustomersIndex;
