import React from "react";
import { Switch, useRouteMatch } from "react-router-dom";
import { PrivateRoute } from "../..";
import OrderDetail from "./OrderDetail";
import Orders from "./Orders";

function OrdersIndex() {
  let { path } = useRouteMatch();

  return (
    <>
      <Switch>
        <PrivateRoute path={`${path}/:orderId`}>
          <OrderDetail />
        </PrivateRoute>
        <PrivateRoute path={`${path}/`}>
          <Orders />
        </PrivateRoute>
      </Switch>
    </>
  );
}

export default OrdersIndex;
