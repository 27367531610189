import { GoogleOutlined } from '@ant-design/icons';
import SyncOutlined from '@ant-design/icons/lib/icons/SyncOutlined';
import { Alert, Button, Space } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { SettingsCalendar } from '../components/SettingsCalendar';
import {
  GoogleApiContext,
  useGoogleApi,
  useGoogleCalendar,
} from '../libs/GoogleSDK';
import { setOrderCalendars } from '../redux/reducers/settingsReducer';
import { OrderCalendar } from '../types';

function Settings() {
  const dispatch = useAppDispatch();
  const { isSignedIn, clientInitialized } = useContext(GoogleApiContext);
  const { orderCalendars } = useAppSelector((state) => state.settings);

  const [orderCalendarItems, setOrderCalendarItems] = useState<OrderCalendar[]>(
    []
  );

  const { signIn, signOut } = useGoogleApi();
  const { listCalendars } = useGoogleCalendar();
  const listCalendarsRef = useRef(listCalendars);

  useEffect(() => {
    if (orderCalendars === undefined) {
      return;
    }
    const calendarItems = Object.entries(orderCalendars).flatMap((i) => i[1]);
    setOrderCalendarItems(calendarItems);
  }, [orderCalendars]);

  const handleSyncCalendars = async () => {
    try {
      if (clientInitialized && isSignedIn) {
        // * Sync calendars on login
        const calendars = await listCalendarsRef.current();

        const calendarRecords: Record<string, OrderCalendar> =
          calendars.items.reduce<Record<string, OrderCalendar>>(
            (prev, curr) => {
              const newOrderCal: Record<string, OrderCalendar> = {
                [curr.id]: {
                  id: curr.id,
                  active: false,
                  name: curr.summary,
                  color: curr.backgroundColor,
                  customerFacingCalendarName: curr.summary,
                },
              };
              return { ...prev, ...newOrderCal };
            },
            {}
          );

        dispatch(setOrderCalendars(calendarRecords));
      }
    } catch (error) {}
  };

  const handleGoogleConnection = async () => {
    try {
      await (isSignedIn ? signOut() : signIn());
    } catch (error) {
      console.error(`Error while google signin or signout `);
    }
  };

  const handleCustomerFacingNameChanged = (cal: OrderCalendar, val: string) => {
    const calCopy = { ...cal };
    const orderCalendarsCopy = { ...orderCalendars };
    calCopy.customerFacingCalendarName = val;
    orderCalendarsCopy[calCopy.id] = calCopy;
    dispatch(setOrderCalendars(orderCalendarsCopy));
  };

  const handleCalendarActiveChanged = (cal: OrderCalendar, val: boolean) => {
    const calCopy = { ...cal };
    const orderCalendarsCopy = { ...orderCalendars };
    calCopy.active = val;
    orderCalendarsCopy[calCopy.id] = calCopy;
    dispatch(setOrderCalendars(orderCalendarsCopy));
  };

  return (
    <div>
      <Title>Einstellungen</Title>

      <Title level={2}>
        <GoogleOutlined style={{ color: '#efefef', fontSize: 24 }} />
      </Title>

      <div>
        <Title level={5}>Verbindung zu Google</Title>
        <Button type='primary' onClick={handleGoogleConnection}>
          {isSignedIn ? 'Trennen' : 'Herstellen'}
        </Button>
      </div>
      <Title level={5}>
        Termin Kalender{' '}
        <Button type='link' onClick={handleSyncCalendars}>
          <SyncOutlined />
        </Button>
      </Title>
      <Space direction='horizontal' size='large' wrap>
        {isSignedIn && (
          <>
            {orderCalendarItems.length === 0 ? (
              <Alert
                message='Bitte Kalender synchronisieren'
                type='info'
                showIcon
              />
            ) : (
              orderCalendarItems!.map((cal) => (
                <SettingsCalendar
                  key={cal.id}
                  active={cal.active}
                  color={cal.color}
                  name={cal.name}
                  customerFacingName={cal.customerFacingCalendarName ?? ''}
                  onActiveChange={(val) =>
                    handleCalendarActiveChanged(cal, val)
                  }
                  onCustomerFacingNameChange={(val) =>
                    handleCustomerFacingNameChanged(cal, val)
                  }
                />
                // <CalendarColorPicker
                //   disabled
                //   key={cal.id}
                //   calendarId={cal.id}
                //   calendarName={cal.name}
                //   color={cal.color}
                //   checked={cal.active}
                //   onChange={handleOrderCalendarChange}
                // />
              ))
            )}
          </>
        )}
      </Space>
    </div>
  );
}

export default Settings;
