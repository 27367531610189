import { ConfigProvider } from 'antd';
import deDE from 'antd/lib/locale/de_DE';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/de';
import React, { createContext, useContext, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import {
  BrowserRouter, Redirect,
  Route,
  useHistory
} from 'react-router-dom';
import App from './App';
import { useAppSelector } from './app/hooks';
import './index.css';
import { GoogleApiProvider } from './libs/GoogleSDK';
import store, { RootState } from './redux/store';
import reportWebVitals from './reportWebVitals';

moment.locale('de');

axios.defaults.baseURL = process.env.REACT_APP_API_URI;
const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const scope = process.env.REACT_APP_GOOGLE_SCOPE;
const discoveryDocs = process.env.REACT_APP_GOOGLE_DISCOVERY_DOCS;

const authContext = createContext({ isAuthenticated: false });
function useAuth() {
  return useContext(authContext);
}

function PrivateRoute({ children, ...rest }: any) {
  // ! Maybe do better typing here
  const auth = useAuth();

  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

function ProvideAuth({ children }: any) {
  const { isAuthenticated } = useAppSelector((state: RootState) => state.auth);

  let history = useHistory();

  useEffect(() => {
    if (isAuthenticated) {
      history.push('/dashboard');
    } else {
      history.replace('/');
    }
  }, [isAuthenticated, history]);

  return (
    <authContext.Provider value={{ isAuthenticated }}>
      {children}
    </authContext.Provider>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <ConfigProvider locale={deDE}>
      <BrowserRouter>
        <Provider store={store}>
          <ProvideAuth>
            <GoogleApiProvider
              apiKey={apiKey}
              clientId={clientId}
              discoveryDocs={discoveryDocs}
              scope={scope}
            >
              <App />
            </GoogleApiProvider>
          </ProvideAuth>
        </Provider>
      </BrowserRouter>
    </ConfigProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

export { PrivateRoute };

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
