const useGoogleApi = () => {
  const signIn = async () => {
    await window.gapi.auth2.getAuthInstance().signIn();
  };

  const signOut = async () => {
    await window.gapi.auth2.getAuthInstance().signOut();
  };

  return {
    signIn,
    signOut,
  };
};

export { useGoogleApi };
