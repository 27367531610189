import { Card, Input, Switch, Tag, Typography } from 'antd';
import React, { FunctionComponent } from 'react';

const { Text } = Typography;
type ComponentProps = {
  name: string;
  color: string;
  active: boolean;
  customerFacingName: string;
  onActiveChange: (val: boolean) => void;
  onCustomerFacingNameChange: (val: string) => void;
};

const SettingsCalendar: FunctionComponent<ComponentProps> = (props) => {
  const {
    color,
    name,
    customerFacingName,
    active,
    onActiveChange,
    onCustomerFacingNameChange,
  } = props;

  return (
    <Card
    style={{width:'300px'}}
      title={name}
      actions={[
        <Switch
          checked={active}
          defaultChecked={false}
          onChange={onActiveChange}
        />,
        <Tag color={color}>Kalenderfarbe</Tag>
      ]}
    >
      <Text>Kalendername für Kunden</Text>
      <Input
      disabled={!active}
        value={customerFacingName}
        onChange={(el) => onCustomerFacingNameChange(el.target.value)}
      />
    </Card>
  );
};

export { SettingsCalendar };
