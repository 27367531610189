const DateSuggestionStatus: Record<string, { query: string; title: string }> = {
  all: {
    query: '',
    title: 'Alle',
  },
  sent: {
    query: 'sent',
    title: 'Gesendet',
  },
  expired: {
    query: 'expired',
    title: 'Abgelaufen',
  },
  officeFeedback: {
    query: 'officeFeedback',
    title: 'Office Feedback',
  },
  userFeedback: {
    query: 'userFeedback',
    title: 'Aktion erforderlich',
  },
  reminderSent: {
    query: 'reminderSent',
    title: 'Erinnerung Gesendet',
  },
  agreed: {
    query: 'agreed',
    title: 'Termin vereinbart',
  },
};

export { DateSuggestionStatus };
