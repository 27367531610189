import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { notification } from 'antd';
import axios from 'axios';
import moment from 'moment';
import {
  CreateAppointmentSuggestion,
  CreateAppointmentSuggestionItem,
  GetAppointmentSuggestion,
  GetOrder,
} from '../../../../rrm-common/dto/order';
import { GetStable } from '../../../../rrm-common/dto/stable';
import { getEventPrefix } from '../../app/utils';
import { useGoogleCalendar } from '../../libs/GoogleSDK';
import { DateSuggestion } from '../../types';
import { RootState } from '../store';

type OrderState = {
  archiveLoading: boolean;
  archiveDone: boolean;
  orders: GetOrder[];
  orderDetail: GetOrder | null;
};

const initialState: OrderState = {
  archiveLoading: false,
  archiveDone: false,
  orders: [],
  orderDetail: null,
};

// * Thunks
export const getOrders = createAsyncThunk<
  GetOrder[],
  { filter?: string },
  { state: RootState }
>('order/getOrders', async (payload, thunkApi) => {
  const response = await axios.get<GetOrder[]>(`order`, {
    params: {
      filter: payload.filter,
    },
  });

  return response.data;
});

export const archiveOrders = createAsyncThunk<
  number[],
  number[],
  { state: RootState }
>('order/archiveOrders', async (payload, thunkApi) => {
  const response = await axios.put<number[]>(`order/admin/archive`, payload);
  thunkApi.dispatch(startArchiveOrders());
  return response.data;
});

export const getOrder = createAsyncThunk<GetOrder, string>(
  'order/getOrder',
  async (orderId, thunkApi) => {
    const response = await axios.get<GetOrder>(`order/${orderId}`);

    return response.data;
  }
);

export const sendOrderTimeSuggest = createAsyncThunk<
  any,
  {
    orderId: string;
    eventTitle: string;
    dateSuggestions: DateSuggestion[];
    expireHours: number;
    comment?: string;
    stable?: GetStable;
  },
  { state: RootState }
>('order/sendOrderTimeSuggest', async (payload, thunkApi) => {
  try {
    const { addEvent } = useGoogleCalendar();
    const { settings } = thunkApi.getState();

    if (settings.orderCalendars === undefined) {
      return;
    }

    const appointmentSuggestionItems: CreateAppointmentSuggestionItem[] = [];

    let stableAddress: string | undefined;
    let stableAddressComment: string | undefined;

    if (payload.stable !== undefined) {
      stableAddress = `${payload.stable.street} ${payload.stable.streetNumber}, ${payload.stable.postalCode} ${payload.stable.city}`;
      stableAddressComment = `**Stall Adresse** \n ${stableAddress}`;
    }

    for (const currSuggestion of payload.dateSuggestions) {
      const createResult = await addEvent(currSuggestion.calendarId, {
        start: {
          dateTime: currSuggestion.timeFrom.toISOString(),
          timeZone: 'Europe/Berlin',
        },
        end: {
          dateTime: currSuggestion.timeTo.toISOString(),
          timeZone: 'Europe/Berlin',
        },
        summary: payload.eventTitle,
        description: stableAddressComment,
        location: stableAddress,
      });

      if (createResult !== undefined) {
        const appointmentSuggestionItemDto: CreateAppointmentSuggestionItem = {
          summary: payload.eventTitle,
          end: currSuggestion.timeTo.toISOString(),
          start: currSuggestion.timeFrom.toISOString(),
          googleCalendarId: currSuggestion.calendarId,
          googleEventId: createResult.id,
          customerFacingCalendarName: currSuggestion.customerFacingCalendarName,
        };

        appointmentSuggestionItems.push(appointmentSuggestionItemDto);
      } else {
        throw Error(`Error adding to Google Calendar. Aborting request.`);
      }
    }

    const appointmentSuggestion: CreateAppointmentSuggestion = {
      orderId: Number.parseInt(payload.orderId),
      expireHours: payload.expireHours,
      comment: payload.comment,
      appointmentSuggestionItems,
    };

    const response = await axios.post<any>(
      `order/admin/datesuggest`,
      appointmentSuggestion
    );

    return response.data;
  } catch (error) {
    console.error(error);
  }
});

export const updateOrderDateSuggest = createAsyncThunk<
  any,
  {
    order: GetOrder;
    appointmentSuggestion: GetAppointmentSuggestion;
  },
  { state: RootState }
>('order/updateOrderDateSuggest', async (payload, thunkApi) => {
  try {
    const { deleteEvent, updateEvent } = useGoogleCalendar();

    if (payload.appointmentSuggestion.active === false) {
      if (payload.appointmentSuggestion.appointmentSuggestionItems) {
        for (const currSuggestionItem of payload.appointmentSuggestion
          .appointmentSuggestionItems) {
          await deleteEvent(
            currSuggestionItem.googleCalendarId!,
            currSuggestionItem.googleEventId!
          );
        }
      }
    } else {
      const { order, appointmentSuggestion } = payload;

      if (appointmentSuggestion.appointmentSuggestionItems) {
        for (const currSuggestionItem of appointmentSuggestion.appointmentSuggestionItems) {
          if (currSuggestionItem.status !== 'officeConfirmed') {
            await deleteEvent(
              currSuggestionItem.googleCalendarId!,
              currSuggestionItem.googleEventId!
            );
          } else {
            const {
              customer,
              stable,
              comment,
              budget,
              horse,
              type,
              rider,
              saddleType,
            } = order;

            const { phone, firstname, lastname } = customer.userProfile;

            const descriptionItems = [`Tel: ${phone}`];

            descriptionItems.push();

            if (horse) {
              descriptionItems.push(
                ...[
                  '\n',
                  '**Pferd**',
                  `Alter: ${moment().from(horse.birthday, true)}`,
                  `Rasse: ${horse.breed}`,
                  `Stockmaß: ${horse.height} cm`,
                ]
              );
            }

            if (rider) {
              descriptionItems.push(
                ...[
                  '\n',
                  '**Hauptreiter:in**',
                  `Größe: ${rider.height} cm`,
                  `Gewicht: ${rider.weight} kg`,
                ]
              );
            }

            if (saddleType) {
              descriptionItems.push(...['\n', '**Sattelart**', saddleType]);
            }

            if (budget) {
              descriptionItems.push(...['\n', '**Bugdet**', `Max. ${budget}€`]);
            }

            if (comment) {
              descriptionItems.push(
                ...['\n', '**Anmerkung des Kunden**', comment]
              );
            }

            if (stable) {
              descriptionItems.push(
                ...[
                  '\n',
                  '**Stalladresse**',
                  `${stable.street} ${stable.streetNumber}, ${stable.postalCode} ${stable.city}`,
                ]
              );
            }

            const description = descriptionItems.join('\n');
            const eventPrefix = getEventPrefix(type, saddleType);

            // * Update google calendar with event data
            updateEvent(
              currSuggestionItem.googleCalendarId!,
              currSuggestionItem.googleEventId!,
              {
                summary: `${eventPrefix}: ${firstname} ${lastname}`,
                location: `${stable?.street} ${stable?.streetNumber}, ${stable?.postalCode} ${stable?.city}`,
                description,
              }
            );
          }
        }
      }
    }

    const response = await axios.put(
      `order/admin/datesuggest/update`,
      payload.appointmentSuggestion
    );

    return response.data;
  } catch (error) {
    console.error(error);
  }
});

export const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setOrders: (state, action: PayloadAction<GetOrder[]>) => {
      state.orders = action.payload;
    },
    startArchiveOrders: (state, action: PayloadAction) => {
      state.archiveLoading = true;
      state.archiveDone = true;
    },
  },
  extraReducers: (builder) => {
    // * Get all orders
    builder.addCase(getOrders.fulfilled, (state, { payload }) => {
      state.orders = payload;
    });

    builder.addCase(getOrders.rejected, (state, { payload }) => {
      // ! DO ERROR handling here....
    });

    // * Get single order
    // ! Add Error case
    builder.addCase(getOrder.fulfilled, (state, { payload }) => {
      state.orderDetail = payload;
    });

    // * Send order timesuggest
    // ! Add Error case
    builder.addCase(sendOrderTimeSuggest.fulfilled, (state, { payload }) => {
      // ! Do state update here
    });

    // * Update dateSuggestion
    // ! Add Error case
    builder.addCase(updateOrderDateSuggest.fulfilled, (state, { payload }) => {
      // ! Do state update here
    });

    // * Set orders to archived
    builder.addCase(archiveOrders.fulfilled, (state, { payload }) => {
      payload.forEach((orderId) => {
        var orderIdx = state.orders.findIndex((order) => {
          return order.id === orderId;
        });
        state.orders[orderIdx].archived = !state.orders[orderIdx].archived;
      });
      state.archiveLoading = false;
    });
    builder.addCase(archiveOrders.rejected, (state, { payload }) => {
      state.archiveLoading = false;
      notification['error']({
        message: 'Archivierung fehlerhaft!',
        description:
          'Beim archivieren ist ein Fehler aufgetreten! Bitte versuche es erneut!',
      });
    });
  },
});

export const { setOrders, startArchiveOrders } = orderSlice.actions;

export default orderSlice.reducer;
