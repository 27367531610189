import {
  CheckboxOptionType,
  Col,
  Input,
  InputNumber,
  Modal,
  notification,
  Radio,
  Row,
  Typography,
} from 'antd';
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useAppSelector } from '../app/hooks';
import { GoogleApiContext, useGoogleCalendar } from '../libs/GoogleSDK';
import { DateSuggestion, OrderCalendar } from '../types';
import { AppFullCalendar } from './AppFullCalendar';
import './TimePreferenceModal.scss';

const { TextArea } = Input;
const { Title } = Typography;

type ComponentProps = {
  visible: boolean;
  closeRequest: (
    result: boolean,
    expireHours?: number,
    comment?: string,
    dateSuggestions?: DateSuggestion[]
  ) => void;
  orderId: string;
};

const TimePreferenceModal: FunctionComponent<ComponentProps> = (props) => {
  const [selectedDates, setSelectedDates] = useState<DateSuggestion[]>([]);
  const [comment, setComment] = useState<string>('');
  const [expireHours, setExpireHours] = useState<number>(24);
  const [calendars, setCalendars] = useState<CheckboxOptionType[]>([]);

  const [activeCalendars, setActiveCalendars] = useState<OrderCalendar[]>([]);
  const [currentSelectedCalendar, setCurrentSelectedCalendar] = useState<
    OrderCalendar | undefined
  >(undefined);

  const { isSignedIn, clientInitialized } = useContext(GoogleApiContext);
  const { listUpcomingEvents } = useGoogleCalendar();
  const listUpcomingEventsRef = useRef(listUpcomingEvents);

  const { orderCalendars } = useAppSelector((state) => state.settings);

  useEffect(() => {
    return () => {
      setSelectedDates([]);
    };
  }, []);

  useEffect(() => {
    const init = async () => {
      if (clientInitialized && isSignedIn && orderCalendars) {
        const calendars = Object.entries(orderCalendars).flatMap((c) => c[1]);
        const activeCalendars = calendars.filter((c) => c.active);

        setCalendars(
          activeCalendars.map((c) => {
            return {
              label: c.name,
              value: c.id,
            };
          })
        );

        setActiveCalendars(activeCalendars);
      }
    };
    if (props.visible) {
      init();
    }
  }, [
    props.visible,
    listUpcomingEventsRef,
    clientInitialized,
    isSignedIn,
    orderCalendars,
  ]);

  const onCancel = () => {
    setSelectedDates([]);
    props.closeRequest(false);
  };

  const onOk = () => {
    props.closeRequest(true, expireHours, comment, selectedDates);
  };

  const handleCalendarSelect = (calendarId: string) => {
    const selectedCalendar = activeCalendars.find((x) => x.id === calendarId);

    if (selectedCalendar === undefined) {
      console.error(`No active calendar found for id`, calendarId);
      notification['error']({
        message: 'Fehler bei Kalenderauswahl',
        description: 'Der ausgewählt Kalender wurde kann nicht gesetzt werden',
      });
      return;
    }
    setCurrentSelectedCalendar(selectedCalendar);
  };

  return (
    <Modal
      title={`Zeitvorschlag für Auftrag ${props.orderId}`}
      visible={props.visible}
      onOk={onOk}
      onCancel={onCancel}
      cancelText='Abbrechen'
      okText='Senden'
      closable={false}
      maskClosable={false}
      width={1500}
      wrapClassName='time-preferences__container'
      centered
    >
      <div>
        <Title level={3}>Kalender</Title>
        <Radio.Group
          options={calendars}
          onChange={(e) => handleCalendarSelect(e.target.value)}
          value={currentSelectedCalendar?.id}
          optionType='button'
        />
      </div>
      <div style={{ height: 600, marginTop: 20 }}>
        <AppFullCalendar
          canAddDates
          onDateAdded={setSelectedDates}
          calendarId={currentSelectedCalendar?.id}
          color={currentSelectedCalendar?.color}
          calendarName={currentSelectedCalendar?.name}
          customerFacingCalendarName={
            currentSelectedCalendar?.customerFacingCalendarName ??
            'Keine Angabe'
          }
        />
      </div>

      <Row gutter={16}>
        <Col span={8}>
          <Title level={3}>Kommentar</Title>
          <TextArea
            showCount
            maxLength={100}
            onChange={(e) => setComment(e.target.value)}
            placeholder='Kommentar für den Kunden'
          />
        </Col>
        <Col>
          <Title level={3}>Ablaufzeit</Title>
          <InputNumber
            min={1}
            max={96}
            defaultValue={expireHours}
            onChange={setExpireHours}
          />{' '}
          Stunden
        </Col>
      </Row>
    </Modal>
  );
};
export default TimePreferenceModal;
