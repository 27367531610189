import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import {
  AuthenticateResult,
  AuthenticateUser,
} from "../../../../rrm-common/dto/authenticate";
import { ACCESS_TOKEN } from "../../app/localStorageConstants";
import { RootState } from "../store";

type AuthState = {
  isAuthenticated: boolean;
  authenticationError: boolean;
  authenticateResult: AuthenticateResult | null;
};

const initialState: AuthState = {
  isAuthenticated: false,
  authenticationError: false,
  authenticateResult: null,
};

// * Thunks
export const authenticateUser = createAsyncThunk<
  AuthenticateResult,
  AuthenticateUser
>("auth/authenticateUser", async (loginData, thunkApi) => {
  const response = await axios.post<AuthenticateResult>(
    "auth/login",
    loginData
  );

  localStorage.setItem(ACCESS_TOKEN, response.data.access_token);

  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${response.data.access_token}`;

  return response.data;
});

export const checkUserIsLoggedIn = createAsyncThunk<
  boolean,
  void,
  {
    state: RootState;
    rejectValue: boolean;
  }
>("auth/checkUserIsLoggedIn", async (data, thunkApi) => {
  const accessToken = localStorage.getItem(ACCESS_TOKEN);

  if (!accessToken) {
    return false;
  }

  axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

  try {
    const response = await axios.get<AuthenticateResult>("auth/profile");

    thunkApi.dispatch(setAuthResult(response.data));

    return true;
  } catch (error) {
    console.error(`Check logged in error`, error);
    return false;
  }
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthResult: (state, action: PayloadAction<AuthenticateResult>) => {
      state.authenticateResult = action.payload;
    },
    logoutUser: (state) => {
      localStorage.removeItem(ACCESS_TOKEN);
      state.isAuthenticated = false;
      state.authenticateResult = null;
      state.authenticationError = false;
    },
  },
  extraReducers: (builder) => {
    // * Authenticate User
    builder.addCase(authenticateUser.fulfilled, (state, { payload }) => {
      state.isAuthenticated = true;
      state.authenticationError = false;
      state.authenticateResult = payload;
    });

    builder.addCase(authenticateUser.rejected, (state, { payload }) => {
      state.isAuthenticated = false;
      state.authenticationError = true;
      state.authenticateResult = null;
    });

    // * Check is logged in
    builder.addCase(checkUserIsLoggedIn.fulfilled, (state, { payload }) => {
      state.isAuthenticated = payload;
    });

    builder.addCase(checkUserIsLoggedIn.rejected, (state, { payload }) => {
      state.isAuthenticated = false;
    });
  },
});

export const { logoutUser, setAuthResult } = authSlice.actions;

export default authSlice.reducer;
