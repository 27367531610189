import React, {
  createContext,
  FunctionComponent,
  useEffect,
  useState,
} from 'react';

declare global {
  interface Window {
    gapi: any;
  }
}

type ComponentProps = {
  apiKey?: string;
  clientId?: string;
  discoveryDocs?: string;
  scope?: string;
};

const script = document.createElement('script');
script.async = true;
script.defer = true;
script.src = 'https://apis.google.com/js/api.js';

document.body.appendChild(script);

const GoogleApiContext = createContext<{
  clientInitialized: boolean;
  isSignedIn: boolean;
}>({
  clientInitialized: false,
  isSignedIn: false,
});

const GoogleApiProvider: FunctionComponent<ComponentProps> = (props) => {
  const [clientInitialized, setClientInitialized] = useState<boolean>(false);
  const [isSignedIn, setIsSignedIn] = useState<boolean>(false);

  useEffect(() => {
    const initClient = async (discoveryDocs: string[]) => {
      await window.gapi.client.init({
        apiKey: props.apiKey,
        clientId: props.clientId,
        discoveryDocs: discoveryDocs,
        scope: props.scope,
      });
      window.gapi.auth2.getAuthInstance().isSignedIn.listen(setIsSignedIn);
      setIsSignedIn(window.gapi.auth2.getAuthInstance().isSignedIn.get());

      setClientInitialized(true);
    };

    // Check required properties
    if (props.apiKey === undefined || props.apiKey === '') {
      console.error(`Google API key must be provided`);
      return;
    }

    if (props.clientId === undefined || props.clientId === '') {
      console.error(`Google client id must be provided`);
      return;
    }

    if (props.scope === undefined || props.scope === '') {
      console.error(
        `Google scopes must be provided as a string separated by blanks`
      );
      return;
    }

    if (props.discoveryDocs === undefined || props.discoveryDocs === '') {
      console.error(
        `Google discovery docs must be provided as a string separated by blanks`
      );
      return;
    }

    const discoveryDocsArray = props.discoveryDocs.split(' ');

    if (discoveryDocsArray.length === 0) {
      console.error(`Error splitting discovery docs`);
      return;
    }

    if (window.gapi) {
      window.gapi.load('client:auth2', initClient);
    }
    script.addEventListener('load', () => {
      if (window.gapi) {
        window.gapi.load('client:auth2', () => initClient(discoveryDocsArray));
      }
    });

    return () => {};
  }, [props.apiKey, props.clientId, props.scope, props.discoveryDocs]);

  return (
    <GoogleApiContext.Provider
      value={{
        clientInitialized,
        isSignedIn,
      }}
    >
      {props.children}
    </GoogleApiContext.Provider>
  );
};

export { GoogleApiProvider, GoogleApiContext };
