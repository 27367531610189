import { Loader } from '@googlemaps/js-api-loader';
import {
  Alert,
  Breadcrumb,
  Button,
  Descriptions,
  Divider,
  List,
  Tag,
  Typography,
} from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import {
  GetAppointmentSuggestion,
  GetAppointmentSuggestionItem,
} from '../../../../rrm-common/dto/order';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { getCurrencyFromNumber, getOrderTypeString } from '../../app/utils';
import TimePreferenceModal from '../../components/TimePreferenceModal';
import {
  getOrder,
  sendOrderTimeSuggest,
  updateOrderDateSuggest,
} from '../../redux/reducers/orderReducer';
import { DateSuggestion } from '../../types';
import './OrderDetail.scss';

const { Title } = Typography;

const gMapsLoader = new Loader({
  apiKey: 'AIzaSyDguN2Iq7LFiWD-_MTs2cLXrFPGcAeLRok',
  version: 'weekly',
  language: 'de',
  region: 'DE',
});

function OrderDetail() {
  const { orderId } = useParams<{ orderId: string }>();

  const navigation = useHistory();

  const [preferredTimeModalVisible, setPreferredTimeModalVisible] =
    useState<boolean>(false);

  const dispatch = useAppDispatch();
  const { orderDetail } = useAppSelector((state) => state.order);

  useEffect(() => {
    if (
      orderDetail !== null &&
      orderDetail.stable !== undefined &&
      gMapsLoader !== undefined
    ) {
      (async () => {
        await gMapsLoader.load();
        const geoCoder = new window.google.maps.Geocoder();

        const geoCodeResult = await geoCoder.geocode({
          address: `${orderDetail.stable!.street} ${
            orderDetail.stable!.streetNumber
          } ${orderDetail.stable!.postalCode} ${orderDetail.stable!.city}`,
        });

        if (geoCodeResult.results.length === 0) {
          console.log(`No geo coordinates found`);
          return;
        }

        const centerPos = {
          lat: geoCodeResult.results[0].geometry.location.lat(),
          lng: geoCodeResult.results[0].geometry.location.lng(),
        };

        const map = new window.google.maps.Map(
          document.getElementById('map') as HTMLElement,
          {
            zoom: 14,
            center: centerPos,
          }
        );

        new window.google.maps.Marker({
          position: centerPos,
          map: map,
        });
      })();
    }
  }, [orderDetail]);

  const handleTimeSuggestionDialogClose = (
    result: boolean,
    expireHours?: number,
    comment?: string,
    dateSuggestions?: DateSuggestion[]
  ) => {
    setPreferredTimeModalVisible(false);

    if (
      !result ||
      dateSuggestions === undefined ||
      dateSuggestions?.length === 0
    ) {
      return;
    }

    dispatch(
      sendOrderTimeSuggest({
        orderId,
        eventTitle: `Blocker: ${orderDetail?.customer.userProfile.firstname} ${orderDetail?.customer.userProfile.lastname}`,
        dateSuggestions: dateSuggestions,
        expireHours: expireHours ?? 24,
        comment,
        stable: orderDetail?.stable,
      })
    );

    navigation.push('/orders');
  };

  const confirmDateSuggestions = () => {
    if (
      orderDetail === null ||
      orderDetail.appointmentSuggestion === null ||
      orderDetail.appointmentSuggestion?.appointmentSuggestionItems ===
        undefined
    ) {
      return;
    }

    const appointmentSuggestionItemsCopy =
      orderDetail.appointmentSuggestion.appointmentSuggestionItems!.map((x) => {
        const returnItem: GetAppointmentSuggestionItem = {
          ...x,
          status: x.status === 'userConfirmed' ? 'officeConfirmed' : 'deleted',
        };
        return returnItem;
      });

    const appointmentSuggestionCopy: GetAppointmentSuggestion = {
      ...orderDetail.appointmentSuggestion,
      appointmentSuggestionItems: appointmentSuggestionItemsCopy,
      status: 'agreed',
    };

    dispatch(
      updateOrderDateSuggest({
        order: orderDetail,
        appointmentSuggestion: appointmentSuggestionCopy,
      })
    );
    navigation.push('/orders');
  };

  const deleteDateSuggestions = () => {
    if (
      orderDetail === null ||
      orderDetail.appointmentSuggestion === null ||
      orderDetail.appointmentSuggestion === undefined
    ) {
      return;
    }

    const appointmentSuggestionItemsCopy =
      orderDetail.appointmentSuggestion.appointmentSuggestionItems!.map((x) => {
        const returnItem: GetAppointmentSuggestionItem = {
          ...x,
          status: 'deleted',
        };
        return returnItem;
      });

    const appointmentSuggestionCopy: GetAppointmentSuggestion = {
      ...orderDetail.appointmentSuggestion,
      appointmentSuggestionItems: appointmentSuggestionItemsCopy,
      status: 'deleted',
      active: false,
    };

    dispatch(
      updateOrderDateSuggest({
        order: orderDetail,
        appointmentSuggestion: appointmentSuggestionCopy,
      })
    );
    navigation.push('/orders');
  };

  useEffect(() => {
    dispatch(getOrder(orderId));
  }, [orderId, dispatch]);

  const renderConfirmationTag = (item: GetAppointmentSuggestionItem) => {
    let confirmationTag: JSX.Element | null = null;

    switch (item.status) {
      case 'default':
        confirmationTag = <Tag color={'blue'}>Kunde Unbestätigt</Tag>;
        break;
      case 'officeConfirmed':
        confirmationTag = <Tag color={'green'}>Vereinbarter Termin</Tag>;
        break;
      case 'userConfirmed':
        confirmationTag = <Tag color={'orange'}>Kunde Bestätigt</Tag>;
        break;
      case 'userDenied':
        confirmationTag = <Tag color={'red'}>Kunde Abgelehnt</Tag>;
        break;
      case 'deleted':
        confirmationTag = <Tag color={'grey'}>Gelöscht</Tag>;
        break;
      default:
        break;
    }

    return confirmationTag;
  };

  const renderSuggestionButton = (
    appointmentSuggestion: GetAppointmentSuggestion
  ) => {
    // * Suggest times

    if (
      appointmentSuggestion === null ||
      appointmentSuggestion.appointmentSuggestionItems === null
    ) {
      return (
        <Button
          disabled={orderDetail?.archived}
          type='primary'
          onClick={() => setPreferredTimeModalVisible(true)}
        >
          Zeiten vorschlagen
        </Button>
      );
    }

    // * Render no button, waiting for user feedback
    if (appointmentSuggestion.status === 'officeFeedback') {
      return null;
    }

    const expired = appointmentSuggestion.status === 'expired';

    const userDenied =
      appointmentSuggestion.appointmentSuggestionItems !== undefined &&
      appointmentSuggestion.status === 'userFeedback' &&
      appointmentSuggestion.appointmentSuggestionItems.findIndex(
        (x) => x.status === 'userDenied'
      ) > -1;

    const userConfirmed =
      appointmentSuggestion.appointmentSuggestionItems !== undefined &&
      appointmentSuggestion.status === 'userFeedback' &&
      appointmentSuggestion.appointmentSuggestionItems.findIndex(
        (x) => x.status === 'userConfirmed'
      ) > -1;

    if (userConfirmed) {
      return (
        <Button
          type='primary'
          disabled={orderDetail?.archived}
          onClick={confirmDateSuggestions}
        >
          Termin bestätigen
        </Button>
      );
    }

    if (userDenied || expired) {
      return (
        <Button
          type='primary'
          disabled={orderDetail?.archived}
          onClick={deleteDateSuggestions}
        >
          Termine löschen
        </Button>
      );
    }

    return null;
  };

  return (
    <>
      <div className='order-detail__container'>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to='/orders'>Anfragen</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{`Auftrag ${orderId} ${
            orderDetail?.archived ? '(Archiviert)' : ''
          }`}</Breadcrumb.Item>
        </Breadcrumb>
        <Divider />
        <Title level={2}>
          Auftrag {orderId} {orderDetail?.archived ? '(Archiviert)' : ''}
        </Title>

        <div className='order-detail__description'>
          {orderDetail && (
            <>
              {/* Date Suggestions */}
              {orderDetail.appointmentSuggestion !== undefined && (
                <>
                  <Divider />
                  <Descriptions
                    title='Termin'
                    layout='vertical'
                    labelStyle={{ color: 'darkgray' }}
                  >
                    <Descriptions.Item label=''>
                      <div>
                        <div style={{ marginBottom: 10 }}>
                          {renderSuggestionButton(
                            orderDetail.appointmentSuggestion
                          )}
                        </div>
                        {orderDetail.appointmentSuggestion !== null &&
                          orderDetail.appointmentSuggestion
                            .appointmentSuggestionItems !== null &&
                          (orderDetail.appointmentSuggestion.status ===
                          'expired' ? (
                            <Alert
                              message='Zeitvorschläge abgelaufen!'
                              type='warning'
                              showIcon
                            />
                          ) : (
                            <>
                              {/* Show info that user was reminded */}
                              {orderDetail.appointmentSuggestion.status ===
                                'reminderSent' && (
                                <Alert
                                  message='Kundenerinnerung versandt'
                                  type='warning'
                                  showIcon
                                />
                              )}
                              {/* Appointment List Items */}
                              <List
                                bordered
                                dataSource={
                                  orderDetail.appointmentSuggestion
                                    .appointmentSuggestionItems
                                }
                                renderItem={(item) => (
                                  <List.Item>
                                    {moment(item.start).format('L')}{' '}
                                    {moment(item.start).format('LT')}
                                    {' - '}
                                    {moment(item.end).format('LT')}{' '}
                                    {renderConfirmationTag(item)}
                                  </List.Item>
                                )}
                              />
                            </>
                          ))}
                      </div>
                    </Descriptions.Item>
                  </Descriptions>
                </>
              )}

              <Divider />

              {/* Stable information */}
              <Descriptions
                title='Stallinformationen'
                layout='vertical'
                labelStyle={{ color: 'darkgray' }}
              >
                <Descriptions.Item label='Adresse'>
                  <div>
                    {`
                    ${orderDetail.stable!.street} ${
                      orderDetail.stable!.streetNumber
                    } ${orderDetail.stable!.postalCode} ${
                      orderDetail.stable!.city
                    }`}
                    <div id='map' className='order-detail__map'></div>
                  </div>
                </Descriptions.Item>
              </Descriptions>

              {/* Order Information */}
              <Divider />
              <Descriptions
                title='Auftraginformationen'
                layout='vertical'
                labelStyle={{ color: 'darkgray' }}
              >
                {/* Name */}
                <Descriptions.Item label='Art'>
                  {getOrderTypeString(orderDetail.type)}
                </Descriptions.Item>

                {/* Order date */}
                <Descriptions.Item label='Datum'>
                  {new Date(orderDetail.createdAt).toLocaleDateString()}
                </Descriptions.Item>

                {/* Preffered time */}
                <Descriptions.Item label='Bevorzugte Zeit'>
                  {orderDetail.preferredTime ? (
                    <>{orderDetail.preferredTime} &nbsp; </>
                  ) : (
                    '--'
                  )}
                </Descriptions.Item>

                {/* Price Or Budget */}
                <Descriptions.Item
                  label={orderDetail.type < 3 ? 'Preis' : 'Budget'}
                >
                  {orderDetail.type < 3
                    ? orderDetail.price
                      ? getCurrencyFromNumber(orderDetail.price)
                      : '--'
                    : orderDetail.budget
                    ? getCurrencyFromNumber(orderDetail.budget)
                    : '--'}
                </Descriptions.Item>

                {/* Saddle Type */}
                <Descriptions.Item label='Sattelart'>
                  {orderDetail.saddleType ?? '--'}
                </Descriptions.Item>

                {/* Comment */}
                <Descriptions.Item label='Kommentar'>
                  {orderDetail.comment ?? '--'}
                </Descriptions.Item>

                {/* Customization */}
                <Descriptions.Item label='Anpassung'>
                  {orderDetail.customization ?? '--'}
                </Descriptions.Item>
              </Descriptions>

              <Divider />

              {/* Customer information */}
              <Descriptions
                title='Kundeninformationen'
                layout='vertical'
                labelStyle={{ color: 'darkgray' }}
              >
                {/* Name */}
                <Descriptions.Item label='Name'>
                  {orderDetail.customer.userProfile.lastname},{' '}
                  {orderDetail.customer.userProfile.firstname}
                </Descriptions.Item>

                <Descriptions.Item label='Email'>
                  <a
                    href={`mailto:${orderDetail.customer.email}`}
                    target='_blank'
                    rel='noreferrer'
                  >
                    {orderDetail.customer.email}
                  </a>
                </Descriptions.Item>

                {/* Address */}
                <Descriptions.Item label='Adresse'>
                  {orderDetail.customer.userProfile.street}{' '}
                  {orderDetail.customer.userProfile.streetNumber},{' '}
                  {orderDetail.customer.userProfile.postalCode}{' '}
                  {orderDetail.customer.userProfile.city}
                </Descriptions.Item>
              </Descriptions>
              {!orderDetail.customer.emailConfirmed && (
                <Alert
                  message='Die EMail Adresse wurde noch nicht bestätigt'
                  type='warning'
                  showIcon
                />
              )}
            </>
          )}
        </div>
      </div>
      <TimePreferenceModal
        visible={preferredTimeModalVisible}
        closeRequest={handleTimeSuggestionDialogClose}
        orderId={orderId}
      />
    </>
  );
}
export default OrderDetail;
