import {
  DashboardOutlined,
  SettingOutlined,
  ShoppingCartOutlined,
} from '@ant-design/icons';
import { Button, Menu, PageHeader } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, Route, Switch } from 'react-router-dom';
import './App.less';
import { useAppDispatch, useAppSelector } from './app/hooks';
import { GOOGLE_CALENDAR_SETTINGS } from './app/localStorageConstants';
import { PrivateRoute } from './index';
import { Customers, Dashboard, Login, Orders, Settings } from './pages';
import { logoutUser } from './redux/reducers/authReducer';
import { setOrderCalendars } from './redux/reducers/settingsReducer';
import { OrderCalendar } from './types';

function App() {
  const dispatch = useAppDispatch();

  const [menuCollapsed] = useState<boolean>(false);
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);

  const handleLogout = () => dispatch(logoutUser());

  useEffect(() => {
    // * Hydrate store

    const googleCalendarSettingsRaw = localStorage.getItem(
      GOOGLE_CALENDAR_SETTINGS
    );

    if (googleCalendarSettingsRaw !== null) {
      const googleCalendarSettings = JSON.parse(
        googleCalendarSettingsRaw
      ) as Record<string, OrderCalendar>;

      dispatch(setOrderCalendars(googleCalendarSettings));
    }
  }, [dispatch]);

  return (
    <div className='app__wrapper'>
      <PageHeader
        title='Reitsport Rheinmain'
        subTitle=''
        className='app__header'
        extra={
          isAuthenticated
            ? [
                <Button key='app-logout' type='default' onClick={handleLogout}>
                  Abmelden
                </Button>,
              ]
            : undefined
        }
      />
      <div className='app__container'>
        {isAuthenticated && (
          <div className='app__side-menu'>
            <Menu
              defaultSelectedKeys={['1']}
              defaultOpenKeys={['sub1']}
              mode='inline'
              theme='light'
              inlineCollapsed={menuCollapsed}
            >
              <Menu.Item key='1' icon={<DashboardOutlined />}>
                <Link to='/dashboard'>Dashboard</Link>
              </Menu.Item>
              <Menu.Item key='2' icon={<ShoppingCartOutlined />}>
                <Link to='/orders'>Anfragen</Link>
              </Menu.Item>
              {/* <Menu.Item key='3' icon={<TeamOutlined />}>
                <Link to='/customer'>Kunden</Link>
              </Menu.Item>
              <Menu.Item key='4' icon={<ToolOutlined />}>
                <Link to='/settings'>Werkstatt</Link>
              </Menu.Item>
              <Menu.Item key='5' icon={<PieChartOutlined />}>
                <Link to='/settings'>Statistik</Link>
              </Menu.Item> */}
              <Menu.Item key='6' icon={<SettingOutlined />}>
                <Link to='/settings'>Einstellungen</Link>
              </Menu.Item>
            </Menu>
          </div>
        )}
        <div className='app__content'>
          <Switch>
            <PrivateRoute path='/dashboard'>
              <Dashboard />
            </PrivateRoute>

            <PrivateRoute path='/orders'>
              <Orders />
            </PrivateRoute>

            <PrivateRoute path='/customer'>
              <Customers />
            </PrivateRoute>

            <PrivateRoute path='/settings'>
              <Settings />
            </PrivateRoute>
            <Route path='/'>
              <Login />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
}

export default App;
