import FullCalendar from '@fullcalendar/react'; //! must go before plugins
import {
  DateSelectArg,
  EventChangeArg,
  EventClickArg,
  EventInput,
  EventSourceInput
} from '@fullcalendar/core';
import deLocale from '@fullcalendar/core/locales/de';
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import timegridPlugin from '@fullcalendar/timegrid';
import { Input, Modal, notification, Spin } from 'antd';
import moment from 'moment';
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { v4 as uuid } from 'uuid';
import { useAppSelector } from '../app/hooks';
import {
  GCalendarEventItem,
  GoogleApiContext,
  useGoogleCalendar
} from '../libs/GoogleSDK';
import { DateSuggestion } from '../types';
import './AppFullCalendar.scss';

const { TextArea } = Input;

const tempCalendarSourceInputId = 'temp';

export type ComponentProps = {
  canAddDates: boolean;
  calendarId?: string;
  calendarName?: string;
  customerFacingCalendarName?: string;
  color?: string;
  onDateAdded?: (dateSuggestions: DateSuggestion[]) => void;
};

const AppFullCalendar: FunctionComponent<ComponentProps> = (props) => {
  const {
    canAddDates,
    onDateAdded,
    calendarId,
    color,
    calendarName,
    customerFacingCalendarName,
  } = props;

  const [eventResult, setEventResult] = useState<EventSourceInput[]>([]);
  const [tempEventSource, setTempEventSource] = useState<EventSourceInput>({
    id: tempCalendarSourceInputId,
    events: [],
  });
  const [googleEvents, setGoogleEvents] = useState<GCalendarEventItem[]>([]);
  const [selectedGoogleEvent, setSelectedGoogleEvent] =
    useState<GCalendarEventItem | null>(null);
  const [showDetailModal, setShowDetailModal] = useState<boolean>(false);
  const [dateSuggestions, setDateSuggestions] = useState<DateSuggestion[]>([]);

  const [loading, setLoading] = useState<boolean>(true);

  const { listUpcomingEvents } = useGoogleCalendar();
  const listUpcomingEventsRef = useRef(listUpcomingEvents);
  const { isSignedIn, clientInitialized } = useContext(GoogleApiContext);
  const { orderCalendars } = useAppSelector((state) => state.settings);

  useEffect(() => {
    if (onDateAdded === undefined) {
      return;
    }

    onDateAdded(dateSuggestions);
  }, [dateSuggestions, onDateAdded]);

  useEffect(() => {
    const init = async () => {
      if (clientInitialized && isSignedIn && orderCalendars) {
        const firstDayOfMonth = moment()
          .startOf('month')
          .subtract(1, 'weeks')
          .toISOString();
        const lastDayOfMonth = moment()
          .endOf('month')
          .add(4, 'weeks')
          .toISOString();
        const calendars = Object.entries(orderCalendars).flatMap((c) => c[1]);
        const activeCalendars = calendars.filter((c) => c.active);

        const events: EventSourceInput[] = [];
        const allGEvents: GCalendarEventItem[] = [];

        for (const currCalendar of activeCalendars) {
          const result = await listUpcomingEventsRef.current(
            currCalendar.id,
            firstDayOfMonth,
            undefined,
            undefined,
            undefined,
            undefined,
            lastDayOfMonth
          );

          const eventInput: EventInput[] = result.items.map((e) => {
            allGEvents.push(e);
            return {
              start: e.start.dateTime ?? e.start.date,
              end: e.end.dateTime ?? e.end.date,
              allDay: e.start.dateTime ? false : true,
              title: e.summary,
              id: e.id,
            };
          });

          events.push({
            events: eventInput,
            color: currCalendar.color,
            id: currCalendar.id,
          });
        }

        setGoogleEvents(allGEvents);
        setEventResult(events);

        setLoading(false);
      }
    };

    init();
  }, [listUpcomingEventsRef, clientInitialized, isSignedIn, orderCalendars]);

  const handleDateClick = (val: DateClickArg) => {
    if (!canAddDates) {
      return;
    }
    console.log(val);
  };

  // * Add an Date suggestion
  const handleAddEvent = (eventSource: EventSourceInput) => {
    if (eventSource === undefined) {
      return;
    }
    if (calendarId === undefined || color === undefined) {
      return;
    }

    const newTempEventSource = { ...(eventSource as any) };

    if (newTempEventSource.events.length === 0) {
      // ! Show error here!
      return;
    }

    // * Always add the last element
    const addEvent =
      newTempEventSource.events[newTempEventSource.events.length - 1];

    const newDateSuggestions: DateSuggestion = {
      id: addEvent.id,
      timeFrom: moment(addEvent.start.toString()),
      timeTo: moment(addEvent.end.toString()),
      calendarId,
      color,
      customerFacingCalendarName: customerFacingCalendarName ?? '',
    };

    setDateSuggestions([...dateSuggestions, newDateSuggestions]);
  };

  // * Remvove an Date suggestion
  const handleRemoveEvent = (eventInput: EventInput) => {
    if (eventInput === undefined) {
      return;
    }

    const dateSuggestionsCopy = [...dateSuggestions];

    const deleteIndex = dateSuggestionsCopy.findIndex(
      (x) => x.id === eventInput.id
    );

    if (deleteIndex === -1) {
      console.log(`Event with id ${deleteIndex} not found`);
    }

    dateSuggestionsCopy.splice(deleteIndex, 1);
    setDateSuggestions(dateSuggestionsCopy);
  };

  // * Handle when event was created
  const handleSelect = (val: DateSelectArg) => {
    if (!canAddDates) {
      return;
    }

    if (calendarId === undefined || color === undefined) {
      notification['error']({
        message: 'Kein Kalender gesetzt',
        description:
          'Es muss erst ein Kalender für den Blocker ausgewählt werden',
      });
      return;
    }

    const tempEvent: EventInput = {
      title: `Blocker ${calendarName} (${customerFacingCalendarName})`,
      start: val.startStr,
      end: val.endStr,
      allDay: false,
      editable: true,
      textColor: '#000000',
      id: uuid(),
      color,
    };

    const eventResultCopy = { ...(tempEventSource as any) };

    const mergedTempEvents = [...eventResultCopy.events, tempEvent];

    eventResultCopy.events = mergedTempEvents;

    setTempEventSource(eventResultCopy);

    handleAddEvent(eventResultCopy);
  };

  // * Handle clicking on an event
  const handleEventClick = (val: EventClickArg) => {
    if (val.event.source?.id === tempCalendarSourceInputId) {
      // * When clicked on a blocker event it should be removed
      const newTempEventSource = { ...(tempEventSource as any) };

      const tempEvents: EventInput[] = [...newTempEventSource.events];

      const delIndex = tempEvents.findIndex((e) => e.id === val.event.id);

      const deleteEvent = tempEvents.splice(delIndex, 1);

      newTempEventSource.events = tempEvents;

      setTempEventSource(newTempEventSource);

      handleRemoveEvent(deleteEvent[0]);
    } else {
      // * When clicking on a saved google event, show event details
      const googleEvent = googleEvents.find((g) => g.id === val.event.id);

      if (googleEvent) {
        setSelectedGoogleEvent(googleEvent);
        setShowDetailModal(true);
      }
    }
  };

  const handleEventChange = (val: EventChangeArg) => {
    if (val.event.source?.id === tempCalendarSourceInputId) {
      const newTempEventSource = { ...(tempEventSource as any) };

      const tempEvents: EventInput[] = [...newTempEventSource.events];

      const delIndex = tempEvents.findIndex((e) => e.id === val.oldEvent.id);

      tempEvents.splice(delIndex, 1, {
        start: val.event.start!,
        end: val.event.end!,
        title: val.event.title,
        allDay: false,
        editable: true,
        textColor: '#000000',
        id: uuid(),
      });

      newTempEventSource.events = tempEvents;

      setTempEventSource(newTempEventSource);
    }
  };

  return (
    <div className='app-full-calendar__container'>
      {loading ? (
        <div className='spinner__container'>
          <Spin tip='Termine werden geladen...' />
        </div>
      ) : (
        <>
          <FullCalendar
            plugins={[timegridPlugin, dayGridPlugin, interactionPlugin]}
            initialView='timeGridWeek'
            locale={deLocale}
            height='100%'
            eventSources={[...eventResult, tempEventSource]}
            nowIndicator
            slotEventOverlap={false}
            selectable
            select={handleSelect}
            dateClick={handleDateClick}
            eventClick={handleEventClick}
            eventChange={handleEventChange}
            headerToolbar={{
              left: 'prev,next today',
              center: 'title',
              right: 'timeGridWeek',
            }}
            selectOverlap
          />
          <Modal
            title={selectedGoogleEvent?.summary ?? 'Termin Details'}
            visible={showDetailModal}
            footer={null}
            onCancel={() => {
              setShowDetailModal(false);
            }}
          >
            {selectedGoogleEvent && (
              <TextArea
                rows={25}
                value={selectedGoogleEvent.description}
                readOnly
              />
            )}
          </Modal>
        </>
      )}
    </div>
  );
};

export { AppFullCalendar };
