import {
  GCalendarCreateEventResult,
  GCalendarEvent,
  GCalendarResult,
} from '../interfaces';
import { GCalendarEventCreate } from '../interfaces/GCalendarEventCreate';

const useGoogleCalendar = () => {
  const listUpcomingEvents = async (
    calendarId: string = 'primary',
    timeMin: string = new Date().toISOString(),
    showDeleted: boolean = false,
    singleEvents: boolean = true,
    maxResults: number = 100,
    orderBy: string = 'startTime',
    timeMax?: string,
  ): Promise<GCalendarEvent> => {
    const response = await window.gapi.client.calendar.events.list({
      calendarId,
      timeMin,
      timeMax,
      showDeleted,
      singleEvents,
      maxResults,
      orderBy,
    });

    return response.result;
  };

  const listCalendars = async (
    maxResults: number = 100,
    showDeleted: boolean = false,
    showHidden: boolean = false,
    syncToken?: string,
    pageToken?: string,
    minAccessRole?: any
  ): Promise<GCalendarResult> => {
    const response = await window.gapi.client.calendar.calendarList.list({
      maxResults,
      minAccessRole,
      pageToken,
      showDeleted,
      showHidden,
      syncToken,
    });
    return response.result;
  };

  const addEvent = async (calendarId: string, event: GCalendarEventCreate) => {
    try {
      const response: GCalendarCreateEventResult =
        await window.gapi.client.calendar.events.insert({
          calendarId,
          resource: event,
        });

      return response.result;
    } catch (error) {
      console.error(error);
    }
  };

  const deleteEvent = async (calendarId: string, eventId: string) => {
    try {
      const response = await window.gapi.client.calendar.events.delete({
        calendarId,
        eventId,
      });

      console.log(response);
      //return response;
    } catch (error) {
      console.error(error);
    }
  };

  const updateEvent = async (
    calendarId: string,
    eventId: string,
    event: Partial<GCalendarEventCreate>
  ) => {
    try {
      const response: GCalendarCreateEventResult =
        await window.gapi.client.calendar.events.patch({
          calendarId,
          eventId,
          resource: event,
        });

      return response.result;
    } catch (error) {
      console.error(error);
    }
  };

  return {
    listUpcomingEvents,
    listCalendars,
    addEvent,
    deleteEvent,
    updateEvent,
  };
};

export { useGoogleCalendar };
