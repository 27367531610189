import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { GetOrder } from '../../../../rrm-common/dto/order';
import { GOOGLE_CALENDAR_SETTINGS } from '../../app/localStorageConstants';
import { OrderCalendar } from '../../types';

type SettingsState = {
  orderCalendars: Record<string, OrderCalendar> | undefined;
};

const initialState: SettingsState = {
  orderCalendars: undefined,
};

// * Thunks
export const getOrders = createAsyncThunk<GetOrder[], void>(
  'order/getOrders',
  async (thunkApi) => {
    const response = await axios.get<GetOrder[]>('order');

    return response.data;
  }
);

export const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setOrderCalendars: (
      state,
      action: PayloadAction<Record<string, OrderCalendar>>
    ) => {
      const orderCalendars = { ...state.orderCalendars, ...action.payload };
      state.orderCalendars = orderCalendars;

      localStorage.setItem(
        GOOGLE_CALENDAR_SETTINGS,
        JSON.stringify(orderCalendars)
      );
    },
  },
  extraReducers: (builder) => {
    // * Get all orders
    builder.addCase(getOrders.fulfilled, (state, { payload }) => {});

    builder.addCase(getOrders.rejected, (state, { payload }) => {
      // ! DO ERROR handling here....
    });
  },
});

export const { setOrderCalendars } = orderSlice.actions;

export default orderSlice.reducer;
