import Title from "antd/lib/typography/Title";
import React from "react";

function Customers() {
  return (
    <div>
      <Title>Kunden</Title>
    </div>
  );
}
export default Customers;
