import Title from 'antd/lib/typography/Title';
import React from 'react';
import { AppFullCalendar } from '../components/AppFullCalendar';
import './Dashboard.scss';

function Dashboard() {
  return (
    <div className='dashboard__container'>
      <Title>Dashboard</Title>
      <Title level={5}>Kalender</Title>
      <div style={{  height: '80%' }}>
        <AppFullCalendar canAddDates={false} />
      </div>
    </div>
  );
}
export default Dashboard;
