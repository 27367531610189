import { Alert, Button, Form, Input, Typography } from "antd";
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import {
  authenticateUser,
  checkUserIsLoggedIn,
} from "../redux/reducers/authReducer";
import "./Login.scss";

type FormData = {
  username: string;
  password: string;
};

const { Title } = Typography;

function Login() {
  const appDispatch = useAppDispatch();
  const { authenticationError } = useAppSelector((state) => state.auth);

  const onSubmit = (data: FormData) => {
    appDispatch(authenticateUser(data));
  };

  useEffect(() => {
    appDispatch(checkUserIsLoggedIn());
  }, [appDispatch]);

  return (
    <div className="login__container">
      <div style={{ width: 500 }}>
        <Title>Login</Title>
        {authenticationError && (
          <Alert message="Fehler bei der Anmeldung" type="error" />
        )}
        <Form onFinish={onSubmit} layout="vertical" size="large" noValidate>
          <Form.Item label="Benutzername" name="username" hasFeedback>
            <Input placeholder="Benutzername" type="email" />
          </Form.Item>

          <Form.Item label="Passwort" name="password" hasFeedback>
            <Input placeholder="Passwort" type="password" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
export default Login;
