import moment from 'moment';
import { GetAppointmentSuggestionItem } from '../../../rrm-common/dto/order';
import { DateSuggestionStatus } from './DateSuggestionConstants';

export const getOrderTypeString = (type: number) => {
  switch (type) {
    case 1:
      return 'Admin Sattelverkauf';
    case 2:
      return 'Admin Sattelservice';
    case 3:
      return 'Sattelkauf';
    case 4:
      return 'Sattelservice';
    default:
      return '';
  }
};

export const getDateSuggestionStatusFormat = (
  status: string,
  appointmentSuggestionItems: GetAppointmentSuggestionItem[] | undefined
): { color: string; text: string } => {
  switch (status) {
    case DateSuggestionStatus.expired.query:
      return { color: 'grey', text: 'Abgelaufen' };
    case DateSuggestionStatus.sent.query:
    case DateSuggestionStatus.officeFeedback.query:
      return { color: 'pink', text: 'Warten auf Kunde' };
    case DateSuggestionStatus.userFeedback.query:
      return { color: 'orange', text: 'Aktion erforderlich' };
    case DateSuggestionStatus.reminderSent.query:
      return { color: 'blue', text: 'Erinnerung gesendet' };
    case DateSuggestionStatus.agreed.query:
      return {
        color: 'green',
        text: `Termin am ${moment(
          appointmentSuggestionItems?.find(
            (x) => x.status === 'officeConfirmed'
          )?.start
        ).format('L')}`,
      };
  }
  return { color: '', text: '' };
};

export const getEventPrefix = (type: number, saddelType?: string) => {
  switch (type) {
    case 3: {
      return `${saddelType ?? '--'}`;
    }
    case 4:
      return 'SK';
  }
};

export const getCurrencyFromNumber = (value: number) =>
  new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
  }).format(value);
